<template>
<div>
  <form action="#">
    <div class="nftmax-personals__history">
      <table id="nftmax-table__main" class="nftmax-table__main nftmax-table__main--profile">
        <thead class="nftmax-table__head">
        <tr>
          <th class="nftmax-table__column-1 nftmax-table__h1">OS</th>
          <th class="nftmax-table__column-2 nftmax-table__h2">Browser</th>
          <th class="nftmax-table__column-3 nftmax-table__h3">Location </th>
          <th class="nftmax-table__column-4 nftmax-table__h4">Last Session</th>
          <th class="nftmax-table__column-5 nftmax-table__h5">Status</th>
          <th class="nftmax-table__column-6 nftmax-table__h6"></th>
        </tr>
        </thead>
        <tbody class="nftmax-table__body">
        <tr>
          <td class="nftmax-table__column-1 nftmax-table__data-1">
            <div class="nftmax-table__product-content">
              <p class="nftmax-table__product-desc">Windows</p>
            </div>
          </td>
          <td class="nftmax-table__column-2 nftmax-table__data-2">
            <div class="nftmax-table__product-content">
              <p class="nftmax-table__product-desc">Google Chorom</p>
            </div>
          </td>
          <td class="nftmax-table__column-3 nftmax-table__data-3">
            <div class="nftmax-table__product-content">
              <p class="nftmax-table__product-desc">Dhaka , Bangladesh</p>
            </div>
          </td>
          <td class="nftmax-table__column-4 nftmax-table__data-4">
            <div class="nftmax-table__product-content">
              <p class="nftmax-table__product-desc">2 days ago</p>
            </div>
          </td>
          <td class="nftmax-table__column-5 nftmax-table__data-5">
            <div class="nftmax-table__product-content">
              <div class="nftmax-table__status__group">
                <div class="nftmax-table__status nftmax-gbcolor">Active</div>
              </div>
            </div>
          </td>
          <td class="nftmax-table__column-6 nftmax-table__data-6">
            <div class="nftmax-table__product-content">
              <div class="nftmax-table__status__group">
                <div class="nftmax-table__trash"><a href="#"><i class="fa-solid fa-trash-can"></i></a></div>
              </div>
            </div>
          </td>
        </tr>

        <tr>
          <td class="nftmax-table__column-1 nftmax-table__data-1">
            <div class="nftmax-table__product-content">
              <p class="nftmax-table__product-desc">Mac</p>
            </div>
          </td>
          <td class="nftmax-table__column-2 nftmax-table__data-2">
            <div class="nftmax-table__product-content">
              <p class="nftmax-table__product-desc">Firefox</p>
            </div>
          </td>
          <td class="nftmax-table__column-3 nftmax-table__data-3">
            <div class="nftmax-table__product-content">
              <p class="nftmax-table__product-desc">Tokyo, Japn</p>
            </div>
          </td>
          <td class="nftmax-table__column-4 nftmax-table__data-4">
            <div class="nftmax-table__product-content">
              <p class="nftmax-table__product-desc">5 days ago</p>
            </div>
          </td>
          <td class="nftmax-table__column-5 nftmax-table__data-5">
            <div class="nftmax-table__product-content">
              <div class="nftmax-table__status__group">
                <div class="nftmax-table__status nftmax-rbcolor">Unactive</div>
              </div>
            </div>
          </td>
          <td class="nftmax-table__column-6 nftmax-table__data-6">
            <div class="nftmax-table__product-content">
              <div class="nftmax-table__status__group">
                <div class="nftmax-table__trash"><a href="#"><i class="fa-solid fa-trash-can"></i></a></div>
              </div>
            </div>
          </td>
        </tr>

        <tr>
          <td class="nftmax-table__column-1 nftmax-table__data-1">
            <div class="nftmax-table__product-content">
              <p class="nftmax-table__product-desc">App</p>
            </div>
          </td>
          <td class="nftmax-table__column-2 nftmax-table__data-2">
            <div class="nftmax-table__product-content">
              <p class="nftmax-table__product-desc">App</p>
            </div>
          </td>
          <td class="nftmax-table__column-3 nftmax-table__data-3">
            <div class="nftmax-table__product-content">
              <p class="nftmax-table__product-desc">Brahin, Dobai</p>
            </div>
          </td>
          <td class="nftmax-table__column-4 nftmax-table__data-4">
            <div class="nftmax-table__product-content">
              <p class="nftmax-table__product-desc">9 days ago</p>
            </div>
          </td>
          <td class="nftmax-table__column-5 nftmax-table__data-5">
            <div class="nftmax-table__product-content">
              <div class="nftmax-table__status__group">
                <div class="nftmax-table__status nftmax-gbcolor">Active</div>
              </div>
            </div>
          </td>
          <td class="nftmax-table__column-6 nftmax-table__data-6">
            <div class="nftmax-table__product-content">
              <div class="nftmax-table__status__group">
                <div class="nftmax-table__trash"><a href="#"><i class="fa-solid fa-trash-can"></i></a></div>
              </div>
            </div>
          </td>
        </tr>

        <tr>
          <td class="nftmax-table__column-1 nftmax-table__data-1">
            <div class="nftmax-table__product-content">
              <p class="nftmax-table__product-desc">Windows</p>
            </div>
          </td>
          <td class="nftmax-table__column-2 nftmax-table__data-2">
            <div class="nftmax-table__product-content">
              <p class="nftmax-table__product-desc">Google Chorom</p>
            </div>
          </td>
          <td class="nftmax-table__column-3 nftmax-table__data-3">
            <div class="nftmax-table__product-content">
              <p class="nftmax-table__product-desc">Dhaka , Bangladesh</p>
            </div>
          </td>
          <td class="nftmax-table__column-4 nftmax-table__data-4">
            <div class="nftmax-table__product-content">
              <p class="nftmax-table__product-desc">2 days ago</p>
            </div>
          </td>
          <td class="nftmax-table__column-5 nftmax-table__data-5">
            <div class="nftmax-table__product-content">
              <div class="nftmax-table__status__group">
                <div class="nftmax-table__status nftmax-gbcolor">Active</div>
              </div>
            </div>
          </td>
          <td class="nftmax-table__column-6 nftmax-table__data-6">
            <div class="nftmax-table__product-content">
              <div class="nftmax-table__status__group">
                <div class="nftmax-table__trash"><a href="#"><i class="fa-solid fa-trash-can"></i></a></div>
              </div>
            </div>
          </td>
        </tr>

        <tr>
          <td class="nftmax-table__column-1 nftmax-table__data-1">
            <div class="nftmax-table__product-content">
              <p class="nftmax-table__product-desc">Mac</p>
            </div>
          </td>
          <td class="nftmax-table__column-2 nftmax-table__data-2">
            <div class="nftmax-table__product-content">
              <p class="nftmax-table__product-desc">Firefox</p>
            </div>
          </td>
          <td class="nftmax-table__column-3 nftmax-table__data-3">
            <div class="nftmax-table__product-content">
              <p class="nftmax-table__product-desc">Tokyo, Japn</p>
            </div>
          </td>
          <td class="nftmax-table__column-4 nftmax-table__data-4">
            <div class="nftmax-table__product-content">
              <p class="nftmax-table__product-desc">5 days ago</p>
            </div>
          </td>
          <td class="nftmax-table__column-5 nftmax-table__data-5">
            <div class="nftmax-table__product-content">
              <div class="nftmax-table__status__group">
                <div class="nftmax-table__status nftmax-rbcolor">Unactive</div>
              </div>
            </div>
          </td>
          <td class="nftmax-table__column-6 nftmax-table__data-6">
            <div class="nftmax-table__product-content">
              <div class="nftmax-table__status__group">
                <div class="nftmax-table__trash"><a href="#"><i class="fa-solid fa-trash-can"></i></a></div>
              </div>
            </div>
          </td>
        </tr>

        <tr>
          <td class="nftmax-table__column-1 nftmax-table__data-1">
            <div class="nftmax-table__product-content">
              <p class="nftmax-table__product-desc">App</p>
            </div>
          </td>
          <td class="nftmax-table__column-2 nftmax-table__data-2">
            <div class="nftmax-table__product-content">
              <p class="nftmax-table__product-desc">App</p>
            </div>
          </td>
          <td class="nftmax-table__column-3 nftmax-table__data-3">
            <div class="nftmax-table__product-content">
              <p class="nftmax-table__product-desc">Brahin, Dobai</p>
            </div>
          </td>
          <td class="nftmax-table__column-4 nftmax-table__data-4">
            <div class="nftmax-table__product-content">
              <p class="nftmax-table__product-desc">9 days ago</p>
            </div>
          </td>
          <td class="nftmax-table__column-5 nftmax-table__data-5">
            <div class="nftmax-table__product-content">
              <div class="nftmax-table__status__group">
                <div class="nftmax-table__status nftmax-gbcolor">Active</div>
              </div>
            </div>
          </td>
          <td class="nftmax-table__column-6 nftmax-table__data-6">
            <div class="nftmax-table__product-content">
              <div class="nftmax-table__status__group">
                <div class="nftmax-table__trash"><a href="#"><i class="fa-solid fa-trash-can"></i></a></div>
              </div>
            </div>
          </td>
        </tr>

        </tbody>
      </table>
    </div>
  </form>
</div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>