<template>
  <div class="charts-main charts-main__invest mg-top-40">
    <div class="charts-main__heading">
      <h4 class="charts-main__title">Investment</h4>
      <div class="nftmax-chart__dropdown">
        <ul  class="nav nav-tabs nftmax-dropdown__list" id="nav-tab" role="tablist">
          <li class="nav-item dropdown">
            <a @click="filterHandler" class="nftmax-sidebar_btn nftmax-heading__tabs nav-link dropdown-toggle show" data-bs-toggle="dropdown"  role="button" aria-expanded="false">Last 30 days <span><svg width="13" height="6" viewBox="0 0 13 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.7" d="M12.4124 0.247421C12.3327 0.169022 12.2379 0.106794 12.1335 0.0643287C12.0291 0.0218632 11.917 0 11.8039 0C11.6908 0 11.5787 0.0218632 11.4743 0.0643287C11.3699 0.106794 11.2751 0.169022 11.1954 0.247421L7.27012 4.07837C7.19045 4.15677 7.09566 4.219 6.99122 4.26146C6.88678 4.30393 6.77476 4.32579 6.66162 4.32579C6.54848 4.32579 6.43646 4.30393 6.33202 4.26146C6.22758 4.219 6.13279 4.15677 6.05312 4.07837L2.12785 0.247421C2.04818 0.169022 1.95338 0.106794 1.84895 0.0643287C1.74451 0.0218632 1.63249 0 1.51935 0C1.40621 0 1.29419 0.0218632 1.18975 0.0643287C1.08531 0.106794 0.990517 0.169022 0.910844 0.247421C0.751218 0.404141 0.661621 0.616141 0.661621 0.837119C0.661621 1.0581 0.751218 1.2701 0.910844 1.42682L4.84468 5.26613C5.32677 5.73605 5.98027 6 6.66162 6C7.34297 6 7.99647 5.73605 8.47856 5.26613L12.4124 1.42682C12.572 1.2701 12.6616 1.0581 12.6616 0.837119C12.6616 0.616141 12.572 0.404141 12.4124 0.247421Z" fill="#374557" fill-opacity="0.6"></path></svg></span></a>
            <ul class="dropdown-menu nftmax-sidebar_dropdown" :class="[filterToggle?'show':'']">
              <a @click="filterHandler" class="list-group-item" data-bs-toggle="list" data-bs-target="#chart__investment" role="tab">Last 7 Days</a>
              <a @click="filterHandler" class="list-group-item" data-bs-toggle="list" data-bs-target="#chart__investment" role="tab">Last 15 Days</a>
              <a @click="filterHandler" class="list-group-item"  data-bs-toggle="list" data-bs-target="#chart__investment" role="tab">Last 30 Days</a>
            </ul>
          </li>
        </ul>
      </div>
    </div>

    <div class="charts-main__one">
      <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade show active" id="chart__investment" role="tabpanel" aria-labelledby="chart__investment">
          <div id="myChart_investment" style="height: 286px">
            <InvestmentLineChart/>
          </div>
          <div class="nftmax-charts__line">
            <span>|</span><span>|</span><span>|</span><span>|</span><span>|</span><span>|</span><span>|</span><span>|</span><span>|</span><span>|</span><span>|</span><span>|</span><span>|</span><span >|</span><span>|</span><span>|</span><span>|</span><span>|</span>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import InvestmentLineChart from "@/components/Charts/InvestmentLineChart";
export default {
  components: {InvestmentLineChart},
  data(){
    return{
      filterToggle:false,
    }
  },
  methods:{
    filterHandler(){
      this.filterToggle=!this.filterToggle
    }
  }
}
</script>

<style scoped>

</style>