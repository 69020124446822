<template>
<!--  history-icon-1.png-->
  <div class="nftmax-history mg-top-40" id="history-card">
    <div class="nftmax-history__main">
      <div class="nftmax-history__content">
        <div class="nftmax-history__icon nftmax-history__icon-one" :style="{'background': iconBg}"><img :src="require(`@/assets/img/${iconSrc}`)" alt="#"></div>
        <div class="nftmax-history__text">
          <h4 class="nftmax-history__number"><span class="number"><CounterUpCom selector="history-card" :endVal="numberOfCount"/></span></h4>
          <p class="nftmax-history__text">{{title}}</p>
          <p class="nftmax-history__amount">+{{numberOfAnalyse}} (11.5%)</p>
        </div>
      </div>
      <div class="nftmax-history__canvas">
        <div class="charts-main__one">
          <div id="myChart_history_one">
            <QuickCounterChart :primaryColor="primaryColor"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CounterUpCom from "@/components/Helper/CounterUpCom";
import QuickCounterChart from "@/components/Charts/QuickCounterChart";
export default {
  components: {QuickCounterChart, CounterUpCom},
  props:[  "title",
    "numberOfCount",
    "numberOfAnalyse",
    "primaryColor",
    'iconBg',
      'iconSrc'
  ]

}
</script>

<style scoped>

</style>