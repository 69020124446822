<template>
  <AuthLayout>
    <div class="nftmax-wc__form">
      <div class="nftmax-wc__form-inner">
        <div class="nftmax-wc__heading">
          <h3 class="nftmax-wc__form-title nftmax-wc__form-title__four" style="background-image:url('../../assets/img/heading-vector-4.png')">Verification Code</h3>
        </div>
        <!-- Sign in Form -->
        <form class="nftmax-wc__form-main" action="#" method="post">
          <div class="form-group">
            <ul class="nftmax-wc__form-verify">
              <input class="nftmax-wc__form-input" type="text" name="text" required="required">
              <input class="nftmax-wc__form-input" type="text" name="text" required="required">
              <input class="nftmax-wc__form-input" type="text" name="text" required="required">
              <input class="nftmax-wc__form-input" type="text" name="text" required="required">
              <input class="nftmax-wc__form-input" type="text" name="text" required="required">
              <input class="nftmax-wc__form-input" type="text" name="text" required="required">
            </ul>
          </div>
          <div class="form-group">
            <div class="nftmax-wc__button">
              <button @click="()=>this.$router.push('password-update')" class="ntfmax-wc__btn" type="button">Continue</button>
            </div>
          </div>
          <div class="form-group">
            <div class="nftmax-wc__bottom">
              <p class="nftmax-wc__text">I dont’t recived a code ? <a href="#">Please resend</a></p>
            </div>
          </div>
        </form>
        <!-- End Sign in Form -->
      </div>
    </div>
  </AuthLayout>
</template>

<script>
import AuthLayout from "@/components/Auth/AuthLayout";
export default {
  components: {AuthLayout}
}
</script>

<style scoped>

</style>