<template>
  <div class="row nftmax-gap-40">
    <div class="col-lg-6 col-12">
      <!-- NFTMax Balance Hover -->
     <RecentTransactionWidget/>
    </div>
    <div class="col-lg-6 col-12">
      <!-- Charts Two -->
      <InvestmentWidget/>
    </div>
  </div>
</template>

<script>
import RecentTransactionWidget from "@/components/MyWallet/RecentTransactionWidget";
import InvestmentWidget from "@/components/MyWallet/InvestmentWidget";
export default {
  components:{
    InvestmentWidget,
    RecentTransactionWidget
  }
}
</script>

<style scoped>

</style>