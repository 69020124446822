<template>
<Layout>
  <div class="nftmax-inner__heading">
    <h2 class="nftmax-inner__page-title">All Notification</h2>
    <div class="all-notificaiton__tabs">
      <!-- Features Tab List -->
      <div class="all-notificaiton__inner nav nav-tabs" id="nav-tab" role="tablist">
        <a @click="tabHandler('today')" class="list-group-item" :class="[activeTab==='today'?'active':'']" data-bs-toggle="list" role="tab">Today <span class="all-notificaiton__count">10</span></a>
        <a @click="tabHandler('days')" class="list-group-item" :class="[activeTab==='days'?'active':'']" data-bs-toggle="list"  role="tab">7 days </a>
        <a @click="tabHandler('all')" class="list-group-item" :class="[activeTab==='all'?'active':'']" data-bs-toggle="list" role="tab">All</a>
      </div>
    </div>
  </div>
  <!-- End All Notification Heading -->

  <div class="tab-content" id="nav-tabContent">
    <!--  Features Single Tab -->
    <div class="tab-pane fade" :class="[activeTab==='today'?'show active':'']" id="notify-one" role="tabpanel">
      <!-- NFTMax Alarm Hover -->
      <div class="nftmax-balance nftmax-alarm__hover all-notificaiton">
        <!-- NFTMax Balance List -->
        <ul class="nftmax-balance_list">
          <li>
            <div class="nftmax-balance-info">
              <div class="nftmax-balance__img nftmax-alarm__default"><img src="../../assets/img/notify-1.png" alt="#"></div>
              <div class="nftmax-alarm__content">
                <h4 class="nftmax-balance-name">Your Account has been created <strong class="nftmax-balance__second">successfully done</strong></h4>
                <p class="nftmax-alarm__text">2 days  ago</p>
              </div>
            </div>
          </li>
          <li>
            <div class="nftmax-balance-info">
              <div class="nftmax-balance__img nftmax-alarm__img-two"><img src="../../assets/img/notify-2.png" alt="#"></div>
              <div class="nftmax-alarm__content">
                <h4 class="nftmax-balance-name">Your Account has been created <strong class="nftmax-balance__second">successfully done</strong></h4>
                <p class="nftmax-alarm__text">2 days  ago</p>
              </div>
            </div>
          </li>
          <li>
            <div class="nftmax-balance-info">
              <div class="nftmax-balance__img nftmax-alarm__img-three"><img src="../../assets/img/notify-3.png" alt="#"></div>
              <div class="nftmax-alarm__content">
                <h4 class="nftmax-balance-name"><strong class="nftmax-balance__second">Thank you !</strong> you made your frast sell  <strong class="nftmax-balance__second">232.98 ETH</strong></h4>
                <p class="nftmax-alarm__text">2 days  ago</p>
              </div>
            </div>
          </li>
          <li>
            <div class="nftmax-balance-info">
              <div class="nftmax-balance__img nftmax-alarm__img-four"><img src="../../assets/img/notify-4.png" alt="#"></div>
              <div class="nftmax-alarm__content">
                <h4 class="nftmax-balance-name"><strong class="nftmax-balance__second">Broklan Simons</strong>Start Following you</h4>
                <p class="nftmax-alarm__text">2 days  ago</p>
              </div>
            </div>
          </li>
          <li>
            <div class="nftmax-balance-info">
              <div class="nftmax-balance__img nftmax-alarm__img-five"><img src="../../assets/img/notify-4.png" alt="#"></div>
              <div class="nftmax-alarm__content">
                <h4 class="nftmax-balance-name">you ranked up and now you are a <strong class="nftmax-balance__second">Auther Master</strong></h4>
                <p class="nftmax-alarm__text">6 days  ago</p>
              </div>
            </div>
          </li>
          <li>
            <div class="nftmax-balance-info">
              <div class="nftmax-balance__img nftmax-alarm__img-two"><img src="../../assets/img/notify-2.png" alt="#"></div>
              <div class="nftmax-alarm__content">
                <h4 class="nftmax-balance-name">Your Account has been created <strong class="nftmax-balance__second">successfully done</strong></h4>
                <p class="nftmax-alarm__text">2 days  ago</p>
              </div>
            </div>
          </li>
          <li>
            <div class="nftmax-balance-info">
              <div class="nftmax-balance__img nftmax-alarm__img-three"><img src="../../assets/img/notify-3.png" alt="#"></div>
              <div class="nftmax-alarm__content">
                <h4 class="nftmax-balance-name"><strong class="nftmax-balance__second">Thank you !</strong> you made your frast sell  <strong class="nftmax-balance__second">232.98 ETH</strong></h4>
                <p class="nftmax-alarm__text">2 days  ago</p>
              </div>
            </div>
          </li>
          <li>
            <div class="nftmax-balance-info">
              <div class="nftmax-balance__img nftmax-alarm__img-four"><img src="../../assets/img/notify-4.png" alt="#"></div>
              <div class="nftmax-alarm__content">
                <h4 class="nftmax-balance-name"><strong class="nftmax-balance__second">Broklan Simons</strong>Start Following you</h4>
                <p class="nftmax-alarm__text">2 days  ago</p>
              </div>
            </div>
          </li>
          <li>
            <div class="nftmax-balance-info">
              <div class="nftmax-balance__img nftmax-alarm__img-five"><img src="../../assets/img/notify-4.png" alt="#"></div>
              <div class="nftmax-alarm__content">
                <h4 class="nftmax-balance-name">you ranked up and now you are a <strong class="nftmax-balance__second">Auther Master</strong></h4>
                <p class="nftmax-alarm__text">6 days  ago</p>
              </div>
            </div>
          </li>
          <li>
            <div class="nftmax-balance-info">
              <div class="nftmax-balance__img nftmax-alarm__default"><img src="../../assets/img/notify-1.png" alt="#"></div>
              <div class="nftmax-alarm__content">
                <h4 class="nftmax-balance-name">Your Account has been created <strong class="nftmax-balance__second">successfully done</strong></h4>
                <p class="nftmax-alarm__text">2 days  ago</p>
              </div>
            </div>
          </li>
        </ul>
        <!-- NFTMax Balance Button -->
        <div class="nftmax-balance__button"><a href="#" class="nftmax-balance__sell-all">See more Notification</a></div>
      </div>
      <!-- End NFTMax Balance Hover -->
    </div>
    <!--  Features Single Tab -->
    <div class="tab-pane fade" :class="[activeTab==='days'?'show active':'']" id="notify-two" role="tabpanel">
      <!-- NFTMax Alarm Hover -->
      <div class="nftmax-balance nftmax-alarm__hover all-notificaiton">
        <!-- NFTMax Balance List -->
        <ul class="nftmax-balance_list">
          <li>
            <div class="nftmax-balance-info">
              <div class="nftmax-balance__img nftmax-alarm__img-three"><img src="../../assets/img/notify-3.png" alt="#"></div>
              <div class="nftmax-alarm__content">
                <h4 class="nftmax-balance-name"><strong class="nftmax-balance__second">Thank you !</strong> you made your frast sell  <strong class="nftmax-balance__second">232.98 ETH</strong></h4>
                <p class="nftmax-alarm__text">2 days  ago</p>
              </div>
            </div>
          </li>
          <li>
            <div class="nftmax-balance-info">
              <div class="nftmax-balance__img nftmax-alarm__default"><img src="../../assets/img/notify-1.png" alt="#"></div>
              <div class="nftmax-alarm__content">
                <h4 class="nftmax-balance-name">Your Account has been created <strong class="nftmax-balance__second">successfully done</strong></h4>
                <p class="nftmax-alarm__text">2 days  ago</p>
              </div>
            </div>
          </li>
          <li>
            <div class="nftmax-balance-info">
              <div class="nftmax-balance__img nftmax-alarm__img-two"><img src="../../assets/img/notify-2.png" alt="#"></div>
              <div class="nftmax-alarm__content">
                <h4 class="nftmax-balance-name">Your Account has been created <strong class="nftmax-balance__second">successfully done</strong></h4>
                <p class="nftmax-alarm__text">2 days  ago</p>
              </div>
            </div>
          </li>
        </ul>
        <!-- NFTMax Balance Button -->
        <div class="nftmax-balance__button"><a href="#" class="nftmax-balance__sell-all">See more Notification</a></div>
      </div>
      <!-- End NFTMax Balance Hover -->
    </div>
    <div class="tab-pane fade" :class="[activeTab==='all'?'show active':'']" id="notify-two" role="tabpanel">
      <!-- NFTMax Alarm Hover -->
      <div class="nftmax-balance nftmax-alarm__hover all-notificaiton">
        <!-- NFTMax Balance List -->
        <ul class="nftmax-balance_list">
          <li>
            <div class="nftmax-balance-info">
              <div class="nftmax-balance__img nftmax-alarm__img-three"><img src="../../assets/img/notify-3.png" alt="#"></div>
              <div class="nftmax-alarm__content">
                <h4 class="nftmax-balance-name"><strong class="nftmax-balance__second">Thank you !</strong> you made your frast sell  <strong class="nftmax-balance__second">232.98 ETH</strong></h4>
                <p class="nftmax-alarm__text">2 days  ago</p>
              </div>
            </div>
          </li>
          <li>
            <div class="nftmax-balance-info">
              <div class="nftmax-balance__img nftmax-alarm__default"><img src="../../assets/img/notify-1.png" alt="#"></div>
              <div class="nftmax-alarm__content">
                <h4 class="nftmax-balance-name">Your Account has been created <strong class="nftmax-balance__second">successfully done</strong></h4>
                <p class="nftmax-alarm__text">2 days  ago</p>
              </div>
            </div>
          </li>
          <li>
            <div class="nftmax-balance-info">
              <div class="nftmax-balance__img nftmax-alarm__img-two"><img src="../../assets/img/notify-2.png" alt="#"></div>
              <div class="nftmax-alarm__content">
                <h4 class="nftmax-balance-name">Your Account has been created <strong class="nftmax-balance__second">successfully done</strong></h4>
                <p class="nftmax-alarm__text">2 days  ago</p>
              </div>
            </div>
          </li>
          <li>
            <div class="nftmax-balance-info">
              <div class="nftmax-balance__img nftmax-alarm__img-two"><img src="../../assets/img/notify-2.png" alt="#"></div>
              <div class="nftmax-alarm__content">
                <h4 class="nftmax-balance-name">Your Account has been created <strong class="nftmax-balance__second">successfully done</strong></h4>
                <p class="nftmax-alarm__text">2 days  ago</p>
              </div>
            </div>
          </li>
        </ul>
        <!-- NFTMax Balance Button -->
        <div class="nftmax-balance__button"><a href="#" class="nftmax-balance__sell-all">See more Notification</a></div>
      </div>
      <!-- End NFTMax Balance Hover -->
    </div>
  </div>
</Layout>
</template>

<script>
import Layout from "@/components/Partials/Layout";
export default {
  components: {Layout},
  data(){
    return{
      activeTab:'today'
    }
  },
  methods:{
    tabHandler(value){
      this.activeTab=value
    }
  }
}
</script>

<style scoped>

</style>