<template>
  <div class="row nftmax-gap-30">
    <div class="col-lg-3 col-md-6 col-12">
      <HistoryCard title="Total Sells"
                   :numberOfCount="71"
                   :numberOfAnalyse="324.75"
                   primaryColor="#5356FB"
                   iconBg="rgba(83, 86, 251, 0.16)" iconSrc="history-icon-1.png"/>
    </div>
    <div class="col-lg-3 col-md-6 col-12">
      <HistoryCard title="Active Customer"
                   :numberOfCount="7583"
                   :numberOfAnalyse="324.75"
                   primaryColor="#F539F8"
                   iconBg="#FEEBFE" iconSrc="history-icon-2.png"/>
    </div>
    <div class="col-lg-3 col-md-6 col-12">
      <HistoryCard title="Total Prodcuts"
                   :numberOfCount="6753"
                   :numberOfAnalyse="324.75"
                   primaryColor="#27AE60"
                   iconBg="#E9F7EF" iconSrc="history-icon-3.png"/>
    </div>
    <div class="col-lg-3 col-md-6 col-12">
      <HistoryCard title="Close Offer"
                   :numberOfCount="745"
                   :numberOfAnalyse="-324.75"
                   primaryColor="#EB5757"
                   iconBg="#FDEEEE" iconSrc="history-icon-4.png"/>
    </div>
  </div>
</template>

<script>
import HistoryCard from "@/components/Helper/Cards/HistoryCard";
export default {
  components: {HistoryCard}
}
</script>

<style scoped>

</style>