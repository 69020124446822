<template>
  <div class="nftmax-collection__single">
    <div class="nftmax-collection__head">
      <a href="#"><img class="nftmax-collection__img" :src="require(`@/assets/img/${datas.images[0]}`)" alt="#"></a>
    </div>
    <div class="nftmax-collection__body">
      <div class="row g-2">
        <div class="col-6">
          <a href="#"><img class="nftmax-collection__img" :src="require(`@/assets/img/${datas.images[1]}`)" alt="#"></a>
        </div>
        <div class="col-6">
          <a href="#"><img class="nftmax-collection__img" :src="require(`@/assets/img/${datas.images[2]}`)" alt="#"></a>
        </div>
      </div>
      <div class="nftmax-collection__author">
        <div class="nftmax-collection__author-head">
          <a href="#">
            <img :src="require(`@/assets/img/${datas.owner}`)" alt="#">
            <h4 class="nftmax-collection__title">{{datas.title}}</h4>
          </a>
        </div>
        <div class="nftmax-collection__item"><a href="#">{{datas.NumberOfItem}}</a></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:["datas"]

}
</script>

<style scoped>

</style>