<template>
  <Layout>
    <div class="nftmax-wallet__dashboard">
      <BitsMonthAndSellMonthSection/>
      <currency-statcs-section/>
      <HistoryAndTopSell/>
      <ProductHistoryTable/>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/components/Partials/Layout";
import BitsMonthAndSellMonthSection from "@/components/Sell/BitsMonthAndSellMonthSection";
import CurrencyStatcsSection from "./CurrencyStaticsSection";
import HistoryAndTopSell from "@/components/Sell/HistoryAndTopSell";
import ProductHistoryTable from "@/components/Sell/ProductHistoryTable";

export default {
  components: {ProductHistoryTable, HistoryAndTopSell, CurrencyStatcsSection, BitsMonthAndSellMonthSection, Layout}
}
</script>

<style scoped>

</style>