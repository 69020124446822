<template>
  <div>
    <div class="nftmax-marketplace__bar mg-top-50 mg-btm-40">
      <div class="nftmax-marketplace__bar-inner">
        <!-- Marketplace Tab List -->
        <div class="list-group nftmax-marketplace__bar-list" id="list-tab" role="tablist">
          <a @click="tabhandler('explore')" :class="[tabActive==='explore'?'active':'']" class="list-group-item" data-bs-toggle="list"  role="tab">Explore</a>
          <a @click="tabhandler('feature')" :class="[tabActive==='feature'?'active':'']" class="list-group-item" data-bs-toggle="list"  role="tab">Featured  Artist</a>
          <a @click="tabhandler('market')" :class="[tabActive==='market'?'active':'']" class="list-group-item" data-bs-toggle="list" role="tab">Open Market</a>
          <a @click="tabhandler('partner')" :class="[tabActive==='partner'?'active':'']" class="list-group-item" data-bs-toggle="list"  role="tab">Partner Shops</a>
          <a @click="tabhandler('assets')" :class="[tabActive==='assets'?'active':'']" class="list-group-item" data-bs-toggle="list"  role="tab">Game Assets</a>
        </div>
        <!-- End Marketplace Tab List -->

        <div class="nftmax-marketplace__bar-right">
          <div class="nftmax-marketplace__bar-one">
            <form class="nftmax-marketplace__bar-search" action="#">
              <button class="search-btn" type="submit"><img src="../../assets/img/search.png" alt="#"></button>
              <input name="s" value="" type="text" placeholder="Search items, collections...">
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="trending-action">
      <div class="row">
        <div class="col-12">
          <div class="tab-content" id="nav-tabContent">
            <!-- Single Tab -->
            <div v-if="tabActive==='explore'"  id="id1" role="tabpanel" aria-labelledby="nav-home-tab">
              <div class="row nftmax-gap-sq30">
                <template v-for="(product,index) in products">
                  <div  :key="index" class="col-lg-4 col-md-6 col-12">
                    <!-- Marketplace Single Item -->
                    <ProductCardStyleTwo :datas="product"/>
                    <!-- End Marketplace Item -->
                  </div>
                </template>
              </div>
            </div>
            <div v-if="tabActive==='feature'"  id="id1" role="tabpanel" aria-labelledby="nav-home-tab">
              <div class="row nftmax-gap-sq30">
                <template v-for="(product,index) in products.slice(3,6)">
                  <div :key="index" class="col-lg-4 col-md-6 col-12">
                    <!-- Marketplace Single Item -->
                    <ProductCardStyleTwo :datas="product"/>
                    <!-- End Marketplace Item -->
                  </div>
                </template>
              </div>
            </div>
            <div  v-if="tabActive==='market'"  id="id1" role="tabpanel" aria-labelledby="nav-home-tab">
              <div class="row nftmax-gap-sq30">
                <template v-for="(product,index) in products.slice(4,6)">
                  <div :key="index" class="col-lg-4 col-md-6 col-12">
                    <!-- Marketplace Single Item -->
                    <ProductCardStyleTwo :datas="product"/>
                    <!-- End Marketplace Item -->
                  </div>
                </template>
              </div>
            </div>
            <div v-if="tabActive==='partner'" id="id1" role="tabpanel" aria-labelledby="nav-home-tab">
              <div class="row nftmax-gap-sq30">
                <template v-for="(product,index) in products.slice(6,8)">
                  <div :key="index" class="col-lg-4 col-md-6 col-12">
                    <!-- Marketplace Single Item -->
                    <ProductCardStyleTwo :datas="product"/>
                    <!-- End Marketplace Item -->
                  </div>
                </template>
              </div>
            </div>
            <div v-if="tabActive==='assets'" id="id1" role="tabpanel" aria-labelledby="nav-home-tab">
              <div class="row nftmax-gap-sq30">
                <template v-for="(product,index) in products.slice(5,7)">
                  <div :key="index" class="col-lg-4 col-md-6 col-12">
                    <!-- Marketplace Single Item -->
                    <ProductCardStyleTwo :datas="product"/>
                    <!-- End Marketplace Item -->
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductCardStyleTwo from "@/components/Helper/Cards/ProductCardStyleTwo";
import marketplaceProducts from "../../data/marketplace_data.json";
export default {
  components: {ProductCardStyleTwo},
  data(){
    return{
      tabActive:"explore",
      products:marketplaceProducts.data
    }
  },
  methods:{
    tabhandler(value){
      this.tabActive=value
    }
  }



}
</script>

<style scoped>

</style>