<template>
  <Layout>
    <UserInfo/>
    <MainSection/>
  </Layout>
</template>

<script>
import Layout from "@/components/Partials/Layout";
import UserInfo from "@/components/Auth/Profile/UserInfo";
import MainSection from "./MainSection";
export default {
  components: {MainSection, UserInfo, Layout}
}
</script>

<style scoped>

</style>