<template>
  <div class="nftmax-newtrans mg-top-40">
    <div class="nftmax-newtrans__box">
      <div class="nftmax-newtrans__heading">
        <h3 class="nftmax-newtrans__title">Recent Transaction</h3>
        <!-- Features Tab List -->
        <div class="nftmax-newtrans__menu list-group" id="list-tab" role="tablist">
          <a @click="tabHandler('all')" class="list-group-item" :class="[activeTab==='all'?'active':'']" data-bs-toggle="list" role="tab">ALL</a>
          <a @click="tabHandler('send')" class="list-group-item" :class="[activeTab==='send'?'active':'']" data-bs-toggle="list"  role="tab">SEND</a>
          <a @click="tabHandler('recent')" class="list-group-item" :class="[activeTab==='recent'?'active':'']" data-bs-toggle="list"  role="tab">RECENT</a>
        </div>
      </div>
      <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade"  :class="[activeTab==='all'?'show active':'']" role="tabpanel">
          <!-- NFTMax Balance List -->
          <ul class="nftmax-newtrans__list">
            <li class="nftmax-newtrans__single">
              <div class="nftmax-newtrans__info">
                <div class="nftmax-newtrans__icon"><img src="../../assets/img/eth-icon.png" alt="#"></div>
                <h4 class="nftmax-newtrans__added">Add ETH from MetaMask <span>22 hours ago</span> </h4>
              </div>
              <div class="nftmax-balance-amount nftmax-newtrans__amount">
                <h4 class="nftmax-balance-amount"><b>$</b>734.79</h4>
                <p class="nftmax-balance__sub"><span class="nftmax-newtrans__credit">+324.75</span></p>
              </div>
            </li>
            <li class="nftmax-newtrans__single">
              <div class="nftmax-newtrans__info">
                <div class="nftmax-newtrans__icon"><img src="../../assets/img/btc-icon.png" alt="#"></div>
                <h4 class="nftmax-newtrans__added">Add ETH from MetaMask <span>22 hours ago</span> </h4>
              </div>
              <div class="nftmax-balance-amount nftmax-newtrans__amount">
                <h4 class="nftmax-balance-amount"><b>$</b>734.79</h4>
                <p class="nftmax-balance__sub"><span class="nftmax-newtrans__debit">-324.75</span></p>
              </div>
            </li>
            <li class="nftmax-newtrans__single">
              <div class="nftmax-newtrans__info">
                <div class="nftmax-newtrans__icon"><img src="../../assets/img/lite-icon.png" alt="#"></div>
                <h4 class="nftmax-newtrans__added">Add ETH from MetaMask <span>22 hours ago</span> </h4>
              </div>
              <div class="nftmax-balance-amount nftmax-newtrans__amount">
                <h4 class="nftmax-balance-amount"><b>$</b>734.79</h4>
                <p class="nftmax-balance__sub"><span class="nftmax-newtrans__debit">-324.75</span></p>
              </div>
            </li>
            <li class="nftmax-newtrans__single">
              <div class="nftmax-newtrans__info">
                <div class="nftmax-newtrans__icon"><img src="../../assets/img/eth-icon.png" alt="#"></div>
                <h4 class="nftmax-newtrans__added">Add ETH from MetaMask <span>22 hours ago</span> </h4>
              </div>
              <div class="nftmax-balance-amount nftmax-newtrans__amount">
                <h4 class="nftmax-balance-amount"><b>$</b>734.79</h4>
                <p class="nftmax-balance__sub"><span class="nftmax-newtrans__credit">+324.75</span></p>
              </div>
            </li>
          </ul>
        </div>

        <div class="tab-pane fade" id="send-trn" :class="[activeTab==='send'?'show active':'']" role="tabpanel">
          <!-- NFTMax Balance List -->
          <ul class="nftmax-newtrans__list">
            <li class="nftmax-newtrans__single">
              <div class="nftmax-newtrans__info">
                <div class="nftmax-newtrans__icon"><img src="../../assets/img/eth-icon.png" alt="#"></div>
                <h4 class="nftmax-newtrans__added">Add ETH from MetaMask <span>22 hours ago</span> </h4>
              </div>
              <div class="nftmax-balance-amount nftmax-newtrans__amount">
                <h4 class="nftmax-balance-amount"><b>$</b>734.79</h4>
                <p class="nftmax-balance__sub"><span class="nftmax-newtrans__credit">+324.75</span></p>
              </div>
            </li>
            <li class="nftmax-newtrans__single">
              <div class="nftmax-newtrans__info">
                <div class="nftmax-newtrans__icon"><img src="../../assets/img/btc-icon.png" alt="#"></div>
                <h4 class="nftmax-newtrans__added">Add ETH from MetaMask <span>22 hours ago</span> </h4>
              </div>
              <div class="nftmax-balance-amount nftmax-newtrans__amount">
                <h4 class="nftmax-balance-amount"><b>$</b>734.79</h4>
                <p class="nftmax-balance__sub"><span class="nftmax-newtrans__debit">-324.75</span></p>
              </div>
            </li>
            <li class="nftmax-newtrans__single">
              <div class="nftmax-newtrans__info">
                <div class="nftmax-newtrans__icon"><img src="../../assets/img/lite-icon.png" alt="#"></div>
                <h4 class="nftmax-newtrans__added">Add ETH from MetaMask <span>22 hours ago</span> </h4>
              </div>
              <div class="nftmax-balance-amount nftmax-newtrans__amount">
                <h4 class="nftmax-balance-amount"><b>$</b>734.79</h4>
                <p class="nftmax-balance__sub"><span class="nftmax-newtrans__debit">-324.75</span></p>
              </div>
            </li>
          </ul>
        </div>

        <div class="tab-pane fade" id="recent-trn" :class="[activeTab==='recent'?'show active':'']" role="tabpanel">
          <!-- NFTMax Balance List -->
          <ul class="nftmax-newtrans__list">
            <li class="nftmax-newtrans__single">
              <div class="nftmax-newtrans__info">
                <div class="nftmax-newtrans__icon"><img src="../../assets/img/eth-icon.png" alt="#"></div>
                <h4 class="nftmax-newtrans__added">Add ETH from MetaMask <span>22 hours ago</span> </h4>
              </div>
              <div class="nftmax-balance-amount nftmax-newtrans__amount">
                <h4 class="nftmax-balance-amount"><b>$</b>734.79</h4>
                <p class="nftmax-balance__sub"><span class="nftmax-newtrans__credit">+324.75</span></p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      activeTab:'all'
    }
  },
  methods:{
    tabHandler(value){
     this.activeTab=value
    }
  }

}
</script>

<style scoped>

</style>