<template>
  <line-chart :options="options" :data="data" />
</template>

<script>
import {
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
} from "chart.js";
import { Line as LineChart } from "vue-chartjs";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip
);
export default {
  components:{
    LineChart
  },
  data(){
    return{
       options : {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: false,
            text: "Chart.js Line Chart",
          },
        },
        lineTension: 0.4,
        scales: {
          x: {
            grid: {
              color: "#E3E4FE",
              lineWidth: 2,
              drawBorder: false,
            },
            gridLines: {
              zeroLineColor: "transparent",
            },
          },

          y: {
            beginAtZero: true,
            grid: {
              display: false,
              drawBorder: false,
            },
            gridLines: {
              zeroLineColor: "transparent",
            },
            ticks: {
              callback(value) {
                return `${value}%`;
              },
            },
          },
        },
        elements: {
          point: {
            radius: [0, 0, 6, 0, 0, 0, 0, 0],
            hoverRadius: 6,
          },
        },
      },
       data : {
        labels:["Jan 1",
          "Jan 2",
          "Jan 3",
          "Jan 4",
          "Jan 5",
          "Jan 6",
          "Jan 7",
          "Jan 8",
          "Jan 9",
          "Jan 10",
          "Jan 11",
          "Jan 12",
          "Jan 13",
          "Jan 14",
          "Jan 15"],
        datasets: [
          {
            label: "Dataset 1",
            data: [
              10, -30, 50, -70, 90, -10, 30, -50, 70, -90, -10, 30, -50, 70, -90,
            ],
            // data: [10, -30, 50, -70, 90, -10, 30, -50, 70, -90],
            borderColor: "#5356FB",
            backgroundColor: "#5356FB",
            borderWidth: 4,
            pointBorderColor: "#ffffff",
            pointBackgroundColor: "#5356FB",
            pointBorderWidth: 3,
            pointHoverBorderWidth: 3,
          },
          {
            label: "Dataset 2",
            data: [
              -20, 40, -60, 80, -90, 20, -40, 60, -80, 90, 20, -40, 60, -80, 90,
            ],
            // data: [-20, 40, -60, 80, -90, 20, -40, 60, -80, 90],
            borderColor: "#F539F8",
            backgroundColor: "#F539F8",
            borderWidth: 4,
            pointBorderColor: "#ffffff",
            pointBackgroundColor: "#f539f8",
            pointBorderWidth: 3,
            pointHoverBorderWidth: 3,
          },
        ],
      },
    }
  }

}
</script>

<style scoped>

</style>