<template>
  <Layout>
    <div class="nftmax-wallet__dashboard">

     <QuickHistorySection/>

      <div class="row">
        <div class="col-12">
          <!-- Charts One -->
          <div class="charts-main  mg-top-30">
            <div class="charts-main__heading">
              <h4 class="charts-main__title">Market History</h4>
              <div class="charts-main__middle">
                <div class="charts-main__middle-single">
                  <p class="charts-main__middle-text">Vistors</p>
                </div>
                <div class="charts-main__middle-single">
                  <p class="charts-main__middle-text nftmax-total__sales">Sells</p>
                </div>
              </div>
              <div class="nftmax-chart__dropdown">
                <ul class="nav nav-tabs nftmax-dropdown__list" id="nav-tab" role="tablist">
                  <li class="nav-item dropdown">
                    <a @click="dropDownFilterHandler" class="nftmax-sidebar_btn nftmax-heading__tabs nav-link dropdown-toggle" :class="[dropDownFilter?'show':'']" data-bs-toggle="dropdown" role="button" aria-expanded="false">Last 15 days <i class="fa-solid fa-angle-down"></i></a>
                    <ul class="dropdown-menu nftmax-sidebar_dropdown" :class="[dropDownFilter?'show':'']">
                      <a @click="dropDownFilterHandler" class="list-group-item" data-bs-toggle="list" data-bs-target="#m_history" role="tab">Last 7 Days</a>
                      <a @click="dropDownFilterHandler" class="list-group-item" data-bs-toggle="list" data-bs-target="#m_history" role="tab">Last 15 Days</a>
                      <a @click="dropDownFilterHandler" class="list-group-item"  data-bs-toggle="list" data-bs-target="#m_history" role="tab">Last 30 Days</a>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div class="charts-main__three">
              <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane fade show active" id="m_history" role="tabpanel" aria-labelledby="nav-home-tab">
                  <div  id="myChart_market_history">
                    <MarketHistoryChart/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- End Charts One -->
        </div>
      </div>
      <SellHistoryAnalytics/>
      <ProductTable/>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/components/Partials/Layout";
import QuickHistorySection from "@/components/History/QuickHistorySection";
import MarketHistoryChart from "@/components/Charts/MarketHistoryChart";
import SellHistoryAnalytics from "@/components/HomePage/SellHistoryAnalytics";
import ProductTable from "@/components/HomePage/ProductTable";
export default {
  components: {ProductTable, SellHistoryAnalytics, MarketHistoryChart, QuickHistorySection, Layout},
  data(){
    return{
      dropDownFilter:false,
    }
  },
  methods:{
    dropDownFilterHandler(){
      this.dropDownFilter=!this.dropDownFilter
    }
  }
}
</script>

<style scoped>

</style>