<template>
  <div class="welcome-cta mg-top-40">
    <div class="welcome-cta__heading">
      <h2 class="welcome-cta__title">Create your own NFT and extraordinary get lot of Sell..</h2>
      <p class="welcome-cta__text nftmax-lspacing">Buy and sell NFTs from the world’s top artists</p>
    </div>
    <div class="welcome-cta__button">
      <a href="upload" class="nftmax-btn nftmax-btn__bordered bg radius">Upload Products</a>
      <router-link to="/marketplace" class="nftmax-btn trs-white bl-color">View Art Work</router-link>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>