<template>
  <line-chart :options="options" :data="data" />
</template>

<script>
import {
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
} from "chart.js";
import { Line as LineChart } from "vue-chartjs";
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip
);

export default {
  components:{
    LineChart
  },
  data(){
    return{
       options: {
        responsive: true,
        elements: {
          line: {
            tension: 0.5,
          },
        },
        plugins: {
          legend: {
            position: "top",
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
              drawBorder: false,
            },
            gridLines: {
              zeroLineColor: "transparent",
            },
          },

          y: {
            beginAtZero: true,
            grid: {
              //   display: false,
              drawBorder: false,
            },
            ticks: {
              callback(value) {
                return `${value}%`;
              },
            },
          },
        },
      },
      data : {
        labels:["Jan 1",
          "Jan 2",
          "Jan 3",
          "Jan 4",
          "Jan 5",
          "Jan 6",
          "Jan 7",
          "Jan 8",
          "Jan 9",
          "Jan 10",
          "Jan 11",
          "Jan 12",
          "Jan 13",
          "Jan 14",
          "Jan 15"],
        datasets: [
          {
            label: "Dataset 1",
            data: [0, 10, 12, 15, 20, 24, 45, 25, 75, 65, 70, 85, 88, 90, 100],
            borderColor: "#5356FB",
            backgroundColor: "#5356FB",
            borderWidth: 5,
          },
          {
            label: "Dataset 2",
            data: [
              0, 15, 15, 18, 23, 30, 40, 28, 78, 68, 73, 88, 88, 90, 100,
            ],
            // data: [4, 14, 20, 34, 40, 90, 60, 75, 80, 95],
            borderColor: "#F539F8",
            backgroundColor: "#F539F8",
            borderWidth: 5,
          },
          {
            label: "Dataset 3",
            data: [
              0, 17, 18, 20, 28, 35, 48, 30, 80, 70, 78, 90, 91, 94, 98,
            ],
            // data: [3, 17, 23, 40, 53, 60, 73, 80, 93],
            borderColor: "#F2994A",
            backgroundColor: "#F2994A",
            borderWidth: 5,
          },
        ],
      }
    }
  }

}
</script>

<style scoped>

</style>