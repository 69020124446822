<template>
  <Layout>
    <MainSection/>
  </Layout>
</template>

<script>
import Layout from "@/components/Partials/Layout";
import MainSection from "@/components/Collections/MainSection";
export default {
  components: {MainSection, Layout}
}
</script>

<style scoped>

</style>