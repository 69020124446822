<template>
  <line-chart height="122px" id="chart" :options="options" :data="chartData" :plugins="plugins"/>
</template>

<script>
import { Line as LineChart } from "vue-chartjs";
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    {
      id: "lineChart",
    }
);
export default {
  components: {LineChart},
  data(){
    return{
      chartData:{
        labels: ["12AM", "4PM", "1.00AM", "2.00AM"],
        datasets: [
          {
            label: "First dataset",
            data:[50, 30, 90, 20],
            fill: true,
            backgroundColor: () => {
              const chart = document.getElementById("chart").getContext("2d");
              const gradient = chart.createLinearGradient(0, 0, 0, 450);
              gradient.addColorStop(0, "rgba(83, 86, 251,0.6)");
              gradient.addColorStop(0.2, "rgba(83, 86, 251, 0.01)");
              return gradient;
            },
            borderColor: "#5356FB",
            pointBackgroundColor: "#5356FB",
          },
        ],
      },
      options:{
        type:"line",
          responsive: true,
          scales: {
        x: {
          grid: {
            display: false,
                drawBorder: false,
            drawOnChartArea:false
          },
          gridLines: {
            zeroLineColor: "transparent",
          },
        },

        y: {
          beginAtZero: true,
              ticks: {
            display: false,
          },
          grid: {
            display: false,
                drawBorder: false,
          },
          gridLines: {
            zeroLineColor: "transparent",
          },
        },
      },
      maintainAspectRatio: false,
          lineTension: 0.5,
          elements: {
          point: {
            radius: [0, 0, 4, 0],
                hoverRadius: 6,
          },
      },
    },
      plugins : [{}]
    }
  }
}
</script>

<style scoped>

</style>