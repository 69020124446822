import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store/index'
import AOS from 'aos'
import 'aos/dist/aos.css'
import VueToastify from "vue-toastify";
Vue.use(VueToastify);
// css
import "../src/assets/css/bootstrap.min.css";
import "../src/assets/css/font-awesome.min.css";
import "../src/assets/css/reset.css";
import "../src/assets/css/style.css";
import "../src/assets/css/overwritten.css";

Vue.config.productionTip = false
new Vue({
  store,
  router,
  render: h => h(App),
  mounted() {
    AOS.init()
  },
}).$mount('#app')
