import { render, staticRenderFns } from "./WalletAndBalanceSection.vue?vue&type=template&id=debdbc10&scoped=true&"
import script from "./WalletAndBalanceSection.vue?vue&type=script&lang=js&"
export * from "./WalletAndBalanceSection.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "debdbc10",
  null
  
)

export default component.exports