import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '../components/HomePage/index.vue'
import ActiveBids from "@/components/ActiveBids";
import Marketplace from "@/components/Marketplace";
import Saved from "@/components/Saved";
import Collections from "@/components/Collections";
import MyWallet from "@/components/MyWallet";
import Notification from "@/components/Notification";
import Sell from "@/components/Sell";
import History from "@/components/History";
import Profile from "@/components/Auth/Profile/index";
import Settings from "@/components/Settings";
import Upload from "@/components/Upload";
import Login from "@/components/Auth/Login";
import Signup from "@/components/Auth/Signup";
import ForgotPassword from "@/components/Auth/ForgotPassword";
import VerifyYou from "@/components/Auth/VerifyYou";
import UpdatePassword from "@/components/Auth/UpdatePassword";
import Message from "@/components/Message";
import MarketPlaceDetails from "@/components/MarketPlaceDetails";

Vue.use(VueRouter)
//routers
const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
    meta: {
      requiresAuth: false,
    }

  },
  {
    path: '/active-bids',
    name: 'ActiveBids',
    component: ActiveBids,
    meta: {
      requiresAuth:true,
    }
  },
  {
    path: '/marketplace',
    name: 'Marketplace',
    component: Marketplace,
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/marketplace-details',
    name: 'MarketPlaceDetails',
    component: MarketPlaceDetails,
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/saved',
    name: 'Saved',
    component: Saved,
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/my-collection',
    name: 'Collections',
    component: Collections,
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/my-wallet',
    name: 'MyWallet',
    component: MyWallet,
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/notification',
    name: 'Notification',
    component: Notification,
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/sell',
    name: 'Sell',
    component: Sell,
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/history',
    name: 'History',
    component: History,
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/upload',
    name: 'Upload',
    component: Upload,
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/verify-you',
    name: 'VerifyYou',
    component: VerifyYou,
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/password-update',
    name: 'UpdatePassword',
    component: UpdatePassword,
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/message',
    name: 'Message',
    component: Message,
    meta: {
      requiresAuth: false,
    }
  },


  
  // {
  //   path: '/additional-page',
  //   name: 'AdditionalSections',
  //   component: AdditionalSections
  // },
  
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // stop stop cache
  scrollBehavior() {
      document.getElementById('app').scrollIntoView();
  }
})
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const isLogin = localStorage.getItem("email");
    if (!isLogin) {
      next("/login");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router
