import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        layoutDrawer:true
    },  
    mutations: {
        SET_DRAWER: (state, value) => {
            state.layoutDrawer = value
        },
    },
    actions: {
       layoutHandler({state,commit}){
           commit('SET_DRAWER',!state.layoutDrawer)
       }
    }
})