<template>
  <Layout>
    <WalletAndBalanceSection/>
    <CurrencyStatcsSection/>
    <RecentTransactionAndInvestment/>
  </Layout>
</template>

<script>
import Layout from "@/components/Partials/Layout";
import WalletAndBalanceSection from "@/components/MyWallet/WalletAndBalanceSection";
import CurrencyStatcsSection from "@/components/MyWallet/CurrencyStatcsSection";
import RecentTransactionAndInvestment from "@/components/MyWallet/RecentTransactionAndInvestment";
export default {
  components: {RecentTransactionAndInvestment, CurrencyStatcsSection, WalletAndBalanceSection, Layout}
}
</script>

<style scoped>

</style>