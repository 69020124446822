<template>
  <div  class="trending-action__single trending-action__single--v2">
    <div class="nftmax-trendmeta">
      <div class="nftmax-trendmeta__main">
        <div class="nftmax-trendmeta__author">
          <div class="nftmax-trendmeta__img">
            <img :src="require(`../../../assets/img/${datas.owner_img}`)" alt="#">
          </div>
          <div class="nftmax-trendmeta__content">
            <span class="nftmax-trendmeta__small">Owned by</span>
            <h4 class="nftmax-trendmeta__title">{{datas.owner}}</h4>
          </div>
        </div>
        <div class="nftmax-trendmeta__author">
          <div class="nftmax-trendmeta__content">
            <span class="nftmax-trendmeta__small">Create by</span>
            <h4 class="nftmax-trendmeta__title">{{datas.creator}}</h4>
          </div>
          <div class="nftmax-trendmeta__img">
            <img :src="require(`../../../assets/img/${datas.creator_img}`)" alt="#">
          </div>
        </div>
      </div>
    </div>
    <!-- Trending Head -->
    <div class="trending-action__head">
      <div v-if="datas.isActive" class="trending-action__badge"><span>Active</span></div>
      <div class="trending-action__button v2">
        <a class="trending-action__btn heart-icon"><i class="fa-solid fa-heart"></i></a>
        <a class="trending-action__btn"><i class="fa-solid fa-ellipsis-vertical"></i></a>
      </div>
      <img :src="require(`@/assets/img/${datas.thumbnil}`)"  alt="#">
    </div>
    <!-- Trending Body -->
    <div class="trending-action__body trending-marketplace__body">
      <h2 class="trending-action__title text-capitalize"><router-link to="/marketplace-details">{{datas.title}}</router-link></h2>
      <div class="nftmax-currency">
        <div class="nftmax-currency__main">
          <div class="nftmax-currency__icon"><img src="../../../assets/img/eth-icon.png" alt="#"></div>
          <div class="nftmax-currency__content">
            <h4 class="nftmax-currency__content-title">{{datas.eth_price}} </h4>
            <p class="nftmax-currency__content-sub">({{datas.usd_price}})</p>
          </div>
        </div>
        <a href="#" class="nftmax-btn nftmax-btn__secondary radius">On Sale</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:["datas"]
}
</script>

<style scoped>

</style>