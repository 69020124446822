<template>
  <div class="row">
    <div class="col-lg-6 col-md-6 col-12">
      <!-- Sign in Form -->
      <form class="nftmax-wc__form-main nftmax-wc__form-main--profile" action="#" method="post">
        <div class="form-group">
          <label class="nftmax-wc__form-label">Old Password</label>
          <div class="form-group__input">
            <span class="nftmax-wc__icon lock"><svg class="inline" width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.4467 7.1581V5.94904C14.4467 2.66455 11.7822 0 8.49771 0C5.21323 0 2.54867 2.66455 2.54867 5.94904V7.1581C1.00076 7.83194 -0.000366211 9.36059 -0.000366211 11.0471V16.149C0.0034843 18.494 1.90178 20.3961 4.25059 20.4H12.7525C15.0975 20.3961 16.9996 18.4978 17.0035 16.149V11.051C16.9919 9.36059 15.9908 7.83579 14.4467 7.1581ZM9.34482 14.451C9.34482 14.9207 8.96362 15.3019 8.49386 15.3019C8.0241 15.3019 7.6429 14.9207 7.6429 14.451V12.749C7.6429 12.2793 8.0241 11.8981 8.49386 11.8981C8.96362 11.8981 9.34482 12.2793 9.34482 12.749V14.451ZM12.7448 6.8H4.24289V5.94904C4.24289 3.60023 6.14505 1.69807 8.49386 1.69807C10.8427 1.69807 12.7448 3.60023 12.7448 5.94904V6.8Z" fill="#374557" fill-opacity="0.6"></path></svg></span>
            <span class="nftmax-wc__icon"><i class="fa-solid fa-eye"></i></span>
            <input class="nftmax-wc__form-input" placeholder="●●●●●●" id="password-field" type="password" name="password" maxlength="8" required="required">
          </div>
        </div>
        <div class="form-group">
          <label class="nftmax-wc__form-label">New Password</label>
          <div class="form-group__input">
            <span class="nftmax-wc__icon lock"><svg class="inline" width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.4467 7.1581V5.94904C14.4467 2.66455 11.7822 0 8.49771 0C5.21323 0 2.54867 2.66455 2.54867 5.94904V7.1581C1.00076 7.83194 -0.000366211 9.36059 -0.000366211 11.0471V16.149C0.0034843 18.494 1.90178 20.3961 4.25059 20.4H12.7525C15.0975 20.3961 16.9996 18.4978 17.0035 16.149V11.051C16.9919 9.36059 15.9908 7.83579 14.4467 7.1581ZM9.34482 14.451C9.34482 14.9207 8.96362 15.3019 8.49386 15.3019C8.0241 15.3019 7.6429 14.9207 7.6429 14.451V12.749C7.6429 12.2793 8.0241 11.8981 8.49386 11.8981C8.96362 11.8981 9.34482 12.2793 9.34482 12.749V14.451ZM12.7448 6.8H4.24289V5.94904C4.24289 3.60023 6.14505 1.69807 8.49386 1.69807C10.8427 1.69807 12.7448 3.60023 12.7448 5.94904V6.8Z" fill="#374557" fill-opacity="0.6"></path></svg></span>
            <span class="nftmax-wc__icon"><i class="fa-solid fa-eye"></i></span>
            <input class="nftmax-wc__form-input" placeholder="●●●●●●" id="password-field" type="password" name="password" maxlength="8" required="required">
          </div>
        </div>
        <div class="form-group">
          <label class="nftmax-wc__form-label">Re-enter Password</label>
          <div class="form-group__input">
            <span class="nftmax-wc__icon lock"><svg class="inline" width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.4467 7.1581V5.94904C14.4467 2.66455 11.7822 0 8.49771 0C5.21323 0 2.54867 2.66455 2.54867 5.94904V7.1581C1.00076 7.83194 -0.000366211 9.36059 -0.000366211 11.0471V16.149C0.0034843 18.494 1.90178 20.3961 4.25059 20.4H12.7525C15.0975 20.3961 16.9996 18.4978 17.0035 16.149V11.051C16.9919 9.36059 15.9908 7.83579 14.4467 7.1581ZM9.34482 14.451C9.34482 14.9207 8.96362 15.3019 8.49386 15.3019C8.0241 15.3019 7.6429 14.9207 7.6429 14.451V12.749C7.6429 12.2793 8.0241 11.8981 8.49386 11.8981C8.96362 11.8981 9.34482 12.2793 9.34482 12.749V14.451ZM12.7448 6.8H4.24289V5.94904C4.24289 3.60023 6.14505 1.69807 8.49386 1.69807C10.8427 1.69807 12.7448 3.60023 12.7448 5.94904V6.8Z" fill="#374557" fill-opacity="0.6"></path></svg></span>
            <span class="nftmax-wc__icon"><i class="fa-solid fa-eye"></i></span>
            <input class="nftmax-wc__form-input" placeholder="●●●●●●" id="password-field" type="password" name="password" maxlength="8" required="required">
          </div>
        </div>
        <div class="form-group">
          <div class="nftmax__item-button--group nftmax__item-button--group--profile">
            <button class="nftmax__item-button--single nftmax__item-button--cancel">Cancel</button>
            <button class="nftmax__item-button--single nftmax-btn nftmax-btn__bordered bg radius " type="submit">Change Password</button>
          </div>
        </div>
      </form>
      <!-- End Sign in Form -->
    </div>
    <div class="col-lg-6 col-md-6 col-12">
      <div class="nftmax-password__img">
        <img src="../../../assets/img/password-reset.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>