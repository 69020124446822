import { render, staticRenderFns } from "./CollectionCard.vue?vue&type=template&id=12fa9ad3&scoped=true&"
import script from "./CollectionCard.vue?vue&type=script&lang=js&"
export * from "./CollectionCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12fa9ad3",
  null
  
)

export default component.exports