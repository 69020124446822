var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashboard-banner nftmax-bg-cover mg-top-40",style:({
    'background-image': 'url(' + require(`../../assets/img/banner-bg.png`) + ')'
})},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"dashboard-banner__main"},[_c('div',{staticClass:"dashboard-banner__column dashboard-banner__column--one"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"dashboard-banner__bids"},[_c('div',{staticClass:"dashboard-banner__bid"},[_vm._m(2),_c('div',{staticClass:"dashboard-banner__middle-border"}),_c('div',{staticClass:"dashboard-banner__group"},[_c('p',{staticClass:"dashboard-banner__group-small"},[_vm._v("Remaing Time")]),_c('CountDown',{attrs:{"lastDate":"2023-03-04 4:00:00"}}),_vm._m(3)],1)])]),_c('div',{staticClass:"dashboard-banner__button"},[_vm._m(4),_c('div',{staticClass:"dashboard-banner__single-btn dashboard-banner__main-btn"},[_c('router-link',{staticClass:"nftmax-btn nftmax-btn__secondary radius",attrs:{"to":"/active-bids"}},[_vm._v("Place a Bid")])],1),_c('div',{staticClass:"dashboard-banner__single-btn"},[_c('router-link',{staticClass:"nftmax-btn trs-white",attrs:{"to":"/marketplace"}},[_vm._v("View Art Work")])],1)])]),_c('div',{staticClass:"dashboard-banner__column dashboard-banner__column--two"},[_c('div',{staticClass:"dashboard-banner__slider"},[_c('VueSlickCarousel',_vm._b({},'VueSlickCarousel',_vm.settings,false),[_c('div',{staticClass:"dashboard-banner__single-slider"},[_c('img',{attrs:{"src":require("../../assets/img/dashboard-slider-1.png"),"alt":"#"}})]),_c('div',{staticClass:"dashboard-banner__single-slider"},[_c('img',{attrs:{"src":require("../../assets/img/slide_2.jpg"),"alt":"#"}})]),_c('div',{staticClass:"dashboard-banner__single-slider"},[_c('img',{attrs:{"src":require("../../assets/img/slide_3.jpg"),"alt":"#"}})])])],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashboard-banner__content"},[_c('h2',{staticClass:"dashboard-banner__title nftmax-font-regular nftmax-lspacing"},[_vm._v("Lock and Lob x Fiesta Spurs")]),_c('p',{staticClass:"dashboard-banner__text nftmax-lspacing"},[_vm._v("ID : 2320382")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nftmax-header__author nftmax-header__author-two"},[_c('div',{staticClass:"nftmax-header__author-img"},[_c('img',{attrs:{"src":require("../../assets/img/profile-pic-2.png"),"alt":"#"}})]),_c('div',{staticClass:"nftmax-header__author-content"},[_c('h4',{staticClass:"nftmax-header__author-title nftmax-header__author-title--two nftmax-lspacing"},[_vm._v("Brokln Simons")]),_c('p',{staticClass:"nftmax-header__author-text nftmax-header__author-text--two"},[_c('a',{staticClass:"nftmax-font-regular nftmax-lspacing",attrs:{"href":"#"}},[_vm._v("@broklinslam_75")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashboard-banner__group"},[_c('p',{staticClass:"dashboard-banner__group-small"},[_vm._v("Current Bid")]),_c('h3',{staticClass:"dashboard-banner__group-title"},[_vm._v("75,320 ETH")]),_c('p',{staticClass:"dashboard-banner__group-small"},[_vm._v("773.69 "),_c('span',[_vm._v("USD")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"dashboard-banner__group-small nftmax-timing"},[_c('span',[_vm._v("Hrs")]),_vm._v(" "),_c('span',[_vm._v("Min")]),_vm._v(" "),_c('span',[_vm._v("Sec")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashboard-banner__single-btn"},[_c('a',{staticClass:"dashboard-banner__heart"},[_c('i',{staticClass:"fa-solid fa-heart"})])])
}]

export { render, staticRenderFns }