<template>
  <Layout>
    <div class="nftmax-dsinner">
      <div class="nftmax-inner__heading mg-btm-20">
        <h2 class="nftmax-inner__page-title mb-0">All Saved</h2>
      </div>
      <MainSection/>
      <!-- Welcome CTA -->
      <UploadProductSectionWidget/>
      <!-- End Welcome CTA -->

    </div>
  </Layout>
</template>

<script>
import Layout from "@/components/Partials/Layout";
import UploadProductSectionWidget from "@/components/HomePage/UploadProductSectionWidget";
import MainSection from "@/components/Saved/MainSection";
export default {
  components: {MainSection, UploadProductSectionWidget, Layout}
}
</script>

<style scoped>

</style>