import { render, staticRenderFns } from "./SellHistoryAnalytic.vue?vue&type=template&id=24246de3&scoped=true&"
import script from "./SellHistoryAnalytic.vue?vue&type=script&lang=js&"
export * from "./SellHistoryAnalytic.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24246de3",
  null
  
)

export default component.exports