<template>
  <AuthLayout>
    <div v-if="finishUpdate" class="nftmax-wc__form">
      <div class="nftmax-wc__form-inner nftmax-wc__form-password bg-mask-img" style="background-image:url('../../assets/img/mask-group-bg.png')">
        <div class="nftmax-wc__heading">
          <h3 class="nftmax-wc__form-title">Password Updated</h3>
        </div>
        <!-- Sign in Form -->
        <form class="nftmax-wc__form-main" action="#" method="post">
          <div class="form-group">
            <div class="nftmax-wc__middle-update">
              <div class="nftmax-wc__update">
                <img src="../../assets/img/sign-icon.png" alt="#">
              </div>
              <div class="nftmax-wc__update-contnet">
                <h2 class="nftmax-wc__update-contnet-title">Thanks You !</h2>
                <p class="nftmax-wc__update-contnet-text">Your password has been updated</p>
              </div>
            </div>
            <div class="nftmax-wc__button nftmax-wc__button-forget">
              <button @click="()=>this.$router.push('login') " class="ntfmax-wc__btn" type="submit">Back to Login</button>
            </div>
          </div>
        </form>
        <!-- End Sign in Form -->
      </div>
    </div>
    <div v-else class="nftmax-wc__form">
      <div class="nftmax-wc__form-inner">
        <div class="nftmax-wc__heading">
          <h3 class="nftmax-wc__form-title nftmax-wc__form-title__three" style="background-image:url('../../assets/img/heading-vector-3.png')">Update Password</h3>
        </div>
        <!-- Sign in Form -->
        <form class="nftmax-wc__form-main" action="#" method="post">
          <div class="form-group">
            <label class="nftmax-wc__form-label">Old Password</label>
            <div class="form-group__input">
              <span class="nftmax-wc__icon"><i class="fa-solid fa-lock"></i></span>
              <input class="nftmax-wc__form-input" placeholder="● ● ● ● ● ●" id="password-field" type="password" name="password" maxlength="8" required="required">
            </div>
          </div>
          <div class="form-group">
            <label class="nftmax-wc__form-label">New Password</label>
            <div class="form-group__input">
              <span class="nftmax-wc__icon"><i class="fa-solid fa-lock"></i></span>
              <input class="nftmax-wc__form-input" placeholder="● ● ● ● ● ●" id="password-field" type="password" name="password" maxlength="8" required="required">
            </div>
          </div>
          <div class="form-group">
            <label class="nftmax-wc__form-label">Re-enter Password</label>
            <div class="form-group__input">
              <span class="nftmax-wc__icon"><i class="fa-solid fa-lock"></i></span>
              <input class="nftmax-wc__form-input" placeholder="● ● ● ● ● ●" id="password-field" type="password" name="password" maxlength="8" required="required">
            </div>
          </div>
          <div class="form-group">
            <div class="nftmax-wc__button">
              <button @click="finishUpdateHandler" class="ntfmax-wc__btn" type="submit">Continue</button>
            </div>
          </div>
        </form>
        <!-- End Sign in Form -->
      </div>
    </div>

  </AuthLayout>
</template>

<script>
import AuthLayout from "@/components/Auth/AuthLayout";
export default {
  components: {AuthLayout},
  data(){
    return{
      finishUpdate:false
    }
  },
  methods:{
    finishUpdateHandler(){
      this.finishUpdate=!this.finishUpdate
    }
  }
}
</script>

<style scoped>

</style>