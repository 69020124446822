<template>
  <div class="nftmax-accordion accordion accordion-flush" id="nftmax-accordion">
    <!-- Single Accordion -->
    <div class="accordion-item nftmax-accordion__single">
      <h2 class="accordion-header" id="nftac-1">
        <button  @click="accHandler('q1')" :class="[acc==='q1'?'collapsed':'']" class="accordion-button collapsed nftmax-accordion__heading" type="button" data-bs-toggle="collapse" data-bs-target="#ac-collapseOne" aria-expanded="false" aria-controls="ac-collapseOne">Can I resell an NFT?</button>
      </h2>
      <div :class="[acc==='q1'?'show':'']" id="ac-collapseOne" class="accordion-collapse collapse" aria-labelledby="nftac-1" data-bs-parent="#nftmax-accordion">
        <div class="accordion-body nftmax-accordion__body">What you do own when you buy an NFT are the keys to a non-fungible – perhaps unique – token. That token is yours to trade or hold or display in Decentraland. But the digital file associated with an NFT is just as easy to copy and paste and download as any other – the Finally, players lose their NFTs sometimes according to the rules and regulations of the NFT game.</div>
      </div>
    </div>
    <!-- End Single Accordion -->
    <!-- Single Accordion -->
    <div class="accordion-item nftmax-accordion__single">
      <h2 class="accordion-header" id="nftac-2">
        <button @click="accHandler('q2')" :class="[acc==='q2'?'collapsed':'']" class="accordion-button collapsed  nftmax-accordion__heading" type="button" data-bs-toggle="collapse" data-bs-target="#ac-collapseTwo" aria-expanded="false" aria-controls="ac-collapseTwo">What can you do with an NFT after buying it?</button>
      </h2>
      <div :class="[acc==='q2'?'show':'']" id="ac-collapseTwo" class="accordion-collapse collapse" aria-labelledby="nftac-2" data-bs-parent="#nftmax-accordion">
        <div class="accordion-body nftmax-accordion__body">What you do own when you buy an NFT are the keys to a non-fungible – perhaps unique – token. That token is yours to trade or hold or display in Decentraland. But the digital file associated with an NFT is just as easy to copy and paste and download as any other – the Finally, players lose their NFTs sometimes according to the rules and regulations of the NFT game.</div>
      </div>
    </div>
    <!-- End Single Accordion -->
    <!-- Single Accordion -->
    <div class="accordion-item nftmax-accordion__single">
      <h2 class="accordion-header" id="nftac-3">
        <button @click="accHandler('q3')" :class="[acc==='q3'?'collapsed':'']" class="accordion-button collapsed  nftmax-accordion__heading" type="button" data-bs-toggle="collapse" data-bs-target="#ac-collapseThree" aria-expanded="false" aria-controls="ac-collapseThree">Can you lose money on NFT?</button>
      </h2>
      <div :class="[acc==='q3'?'show':'']" id="ac-collapseThree" class="accordion-collapse collapse" aria-labelledby="nftac-3" data-bs-parent="#nftmax-accordion">
        <div class="accordion-body nftmax-accordion__body">What you do own when you buy an NFT are the keys to a non-fungible – perhaps unique – token. That token is yours to trade or hold or display in Decentraland. But the digital file associated with an NFT is just as easy to copy and paste and download as any other – the Finally, players lose their NFTs sometimes according to the rules and regulations of the NFT game.</div>
      </div>
    </div>
    <!-- End Single Accordion -->
    <!-- Single Accordion -->
    <div class="accordion-item nftmax-accordion__single">
      <h2 class="accordion-header" id="nftac-4">
        <button @click="accHandler('q4')" :class="[acc==='q4'?'collapsed':'']" class="accordion-button collapsed  nftmax-accordion__heading" type="button" data-bs-toggle="collapse" data-bs-target="#ac-collapseFour" aria-expanded="false" aria-controls="ac-collapseFour">How does NFT prove ownership?</button>
      </h2>
      <div :class="[acc==='q4'?'show':'']" id="ac-collapseFour" class="accordion-collapse collapse" aria-labelledby="nftac-4" data-bs-parent="#nftmax-accordion">
        <div class="accordion-body nftmax-accordion__body">What you do own when you buy an NFT are the keys to a non-fungible – perhaps unique – token. That token is yours to trade or hold or display in Decentraland. But the digital file associated with an NFT is just as easy to copy and paste and download as any other – the Finally, players lose their NFTs sometimes according to the rules and regulations of the NFT game.</div>
      </div>
    </div>
    <!-- End Single Accordion -->
    <!-- Single Accordion -->
    <div class="accordion-item nftmax-accordion__single">
      <h2 class="accordion-header" id="nftac-5">
        <button @click="accHandler('q5')" :class="[acc==='q5'?'collapsed':'']" class="accordion-button collapsed  nftmax-accordion__heading" type="button" data-bs-toggle="collapse" data-bs-target="#ac-collapseFive" aria-expanded="false" aria-controls="ac-collapseFive">How can you tell if someone owns an NFT?</button>
      </h2>
      <div :class="[acc==='q5'?'show':'']" id="ac-collapseFive" class="accordion-collapse collapse" aria-labelledby="nftac-5" data-bs-parent="#nftmax-accordion">
        <div class="accordion-body nftmax-accordion__body">What you do own when you buy an NFT are the keys to a non-fungible – perhaps unique – token. That token is yours to trade or hold or display in Decentraland. But the digital file associated with an NFT is just as easy to copy and paste and download as any other – the Finally, players lose their NFTs sometimes according to the rules and regulations of the NFT game.</div>
      </div>
    </div>
    <!-- End Single Accordion -->
    <!-- Single Accordion -->
    <div class="accordion-item nftmax-accordion__single">
      <h2 class="accordion-header" id="nftac-6">
        <button @click="accHandler('q6')" :class="[acc==='q6'?'collapsed':'']" class="accordion-button collapsed  nftmax-accordion__heading" type="button" data-bs-toggle="collapse" data-bs-target="#ac-collapseSix" aria-expanded="false" aria-controls="ac-collapseSix">Can you lose more than you invest in NFTs?</button>
      </h2>
      <div :class="[acc==='q6'?'show':'']" id="ac-collapseSix" class="accordion-collapse collapse" aria-labelledby="nftac-6" data-bs-parent="#nftmax-accordion">
        <div class="accordion-body nftmax-accordion__body">What you do own when you buy an NFT are the keys to a non-fungible – perhaps unique – token. That token is yours to trade or hold or display in Decentraland. But the digital file associated with an NFT is just as easy to copy and paste and download as any other – the Finally, players lose their NFTs sometimes according to the rules and regulations of the NFT game.</div>
      </div>
    </div>
    <!-- End Single Accordion -->
    <!-- Single Accordion -->
    <div class="accordion-item nftmax-accordion__single">
      <h2 class="accordion-header" id="nftac-7">
        <button @click="accHandler('q7')" :class="[acc==='q7'?'collapsed':'']" class="accordion-button collapsed  nftmax-accordion__heading" type="button" data-bs-toggle="collapse" data-bs-target="#ac-collapseSeven" aria-expanded="false" aria-controls="ac-collapseSeven">What can you do with an NFT after buying it?</button>
      </h2>
      <div :class="[acc==='q7'?'show':'']" id="ac-collapseSeven" class="accordion-collapse collapse" aria-labelledby="nftac-7" data-bs-parent="#nftmax-accordion">
        <div class="accordion-body nftmax-accordion__body">What you do own when you buy an NFT are the keys to a non-fungible – perhaps unique – token. That token is yours to trade or hold or display in Decentraland. But the digital file associated with an NFT is just as easy to copy and paste and download as any other – the Finally, players lose their NFTs sometimes according to the rules and regulations of the NFT game.</div>
      </div>
    </div>
    <!-- End Single Accordion -->
  </div>
</template>

<script>
export default {
  data(){
    return{
      acc:'q1'
    }
  },
  methods:{
    accHandler(value){
      this.acc=value
    }
  }

}
</script>

<style scoped>

</style>