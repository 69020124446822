<template>
  <div class="nftmax-wallet__dashboard">
    <div class="row nftmax-gap-40">
      <div class="col-lg-6 col-6 col-12 nftmax-wallet__one">
        <!-- NFTMax Balance Hover -->
        <BalanceWidget/>
      </div>
      <div class="col-lg-6 col-6 col-12 nftmax-wallet__two">
        <!-- NFTMax Balance Hover -->
        <div class="nftmax-balance__all mg-top-30">
          <h3 class="nftmax-balance__all-title">My Wallet</h3>
          <!-- NFTMax Balance List -->
          <ul class="nftmax-balance_list">
            <li class="nftmax-balance__all-single">
              <div class="nftmax-balance-info">
                <div class="nftmax-balance__img"><img src="../../assets/img/wallet-1.png" alt="#"></div>
                <h4 class="nftmax-balance-name">MetaMask</h4>
              </div>
              <div class="nftmax-balance-desc">
                <div class="nftmax-balance-desc__main">
                  <h4 class="nftmax-balance-amount"><b>$</b>734.79</h4>
                  <p class="nftmax-balance__sub">New Add <span>+324.75</span></p>
                </div>
                <div class="nftmax-dots"><img src="../../assets/img/dots.png" alt="#"></div>
              </div>
            </li>
            <li class="nftmax-balance__all-single">
              <div class="nftmax-balance-info">
                <div class="nftmax-balance__img"><img src="../../assets/img/wallet-2.png" alt="#"></div>
                <h4 class="nftmax-balance-name">Coinbase Wallet</h4>
              </div>
              <div class="nftmax-balance-desc">
                <div class="nftmax-balance-desc__main">
                  <h4 class="nftmax-balance-amount"><b>$</b>299.61</h4>
                  <p class="nftmax-balance__sub">New Add <span>+324.75</span></p>
                </div>
                <div class="nftmax-dots"><img src="../../assets/img/dots.png" alt="#"></div>
              </div>
            </li>
            <li class="nftmax-balance__all-single">
              <div class="nftmax-balance-info">
                <div class="nftmax-balance__img"><img src="../../assets/img/wallet-3.png" alt="#"></div>
                <h4 class="nftmax-balance-name">Bitski</h4>
              </div>
              <div class="nftmax-balance-desc">
                <div class="nftmax-balance-desc__main">
                  <h4 class="nftmax-balance-amount"><b>$</b>512.44</h4>
                  <p class="nftmax-balance__sub">New Add <span>+324.75</span></p>
                </div>
                <div class="nftmax-dots"><img src="../../assets/img/dots.png" alt="#"></div>
              </div>
            </li>
            <li class="nftmax-balance__all-single">
              <div class="nftmax-balance-info">
                <div class="nftmax-balance__img"><img src="../../assets/img/wallet-4.png" alt="#"></div>
                <h4 class="nftmax-balance-name">WalletConnect</h4>
              </div>
              <div class="nftmax-balance-desc">
                <div class="nftmax-balance-desc__main">
                  <h4 class="nftmax-balance-amount"><b>$</b>992.99</h4>
                  <p class="nftmax-balance__sub">New Add <span>+324.75</span></p>
                </div>
                <div class="nftmax-dots"><img src="../../assets/img/dots.png" alt="#"></div>
              </div>
            </li>
          </ul>
        </div>
        <!-- End NFTMax Balance Hover -->
      </div>
    </div>
  </div>
</template>

<script>
import BalanceWidget from "@/components/HomePage/BalanceWidget";
export default {
  components: {BalanceWidget}
}
</script>

<style scoped>

</style>