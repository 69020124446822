<template>
  <span v-if="visible">
    <ICountUp
        :delay="delay"
        :endVal="endVal"
        :options="options"
        @ready="onReady"
    />
  </span>

</template>

<script>
import ICountUp from 'vue-countup-v2';
export default {
  props:['endVal','selector'],
  components: {ICountUp},
  data(){
    return{
      visible:false,
      counterPositions:null,
      delay: 1000,
      options: {
        useEasing: true,
        useGrouping: true,
        separator: ',',
        decimal: '.',
        prefix: '',
        suffix: ''
      }
    }
  },
  mounted() {
    var rec = document.getElementById(`${this.selector}`);
    var currentPosition = rec.offsetTop - document.body.scrollTop;
    this.counterPositions=currentPosition;
    if(this.counterPositions<100){
      this.starting();
    }else if(this.visible===false&&this.counterPositions){
      window.addEventListener('scroll',()=>{
        var currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
        console.log(currentScrollPosition)
        if(this.counterPositions){
          console.log(currentScrollPosition + 500>this.counterPositions)
          if(currentScrollPosition + 500>this.counterPositions){
            this.starting()
          }
        }

      })
    }else{
      this.visible=false;
      this.counterPositions=null;
    }

  },
  methods: {
    onReady(instance) {
      const that = this;
      instance.update(that.endVal);
    },
    starting(){
      this.visible = true;
      this.counterPositions=null
    }
  }
}
</script>

<style>
</style>